<template>
  <v-container fluid dark class="d-flex align-center flex-column pb-10">
    <div class="d-flex justify-space-around flex-wrap footer-social-media">
      <div class="social-media-buttons">
        <v-btn icon  v-for="item of social_media" :key="item.name"
          @click="redirect(item.url)"
        >
          <v-icon
          >mdi-{{item.name}}</v-icon>
        </v-btn>
      </div>
      <div>
        <v-select
          v-model="lang"
          :items="languages"
          menu-props="auto"
          hide-details
          prepend-icon="mdi-earth"
          single-line
          dark
          dense
        ></v-select>
      </div>
    </div>
    <v-divider />
    <div class="ins-footer d-flex justify-space-around flex-wrap">
      <div class="logo-acs">
        <v-img
         :src="require('../../assets/logos/logo_hor.png')"
         width="300px">
        </v-img>
      </div>
      <div class="legal-text">
        <div>
          <span>
            <a>{{ $t('footer.policy') }}</a>
            <v-divider vertical></v-divider>
            <a>{{ $t('footer.serve') }}</a>
            <v-divider vertical></v-divider>
            <a>{{ $t('footer.about') }}</a>
            <v-divider vertical></v-divider>
            <a href="mailto:design@astralcavestudio.com">{{ $t('footer.contact') }}</a>
          </span>
        </div>
        <div class="text-caption ml-4">Copyrigth © 2021 Astral Cave Studio All rights
          reserved</div>
      </div>
    </div>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      lang: 'Español',
      languages: ['Español', 'English'],
      social_media: [
        {
          name: 'twitter',
          url: 'https://www.twitter.com/astralc_design',
        },
        {
          name: 'instagram',
          url: 'https://www.instagram.com/astralc_design',
        },
      ],
    };
  },
  methods: {
    redirect(url) {
      window.open(url);
    },
  },
  beforeUpdate() {
    if (this.lang === 'Español') {
      this.$i18n.locale = 'es';
    } else if (this.lang === 'English') {
      this.$i18n.locale = 'en';
    } else {
      this.$i18n.locale = 'es';
    }
  },
};
</script>

<style lang="scss" scoped>
.ins-footer {
  color: white;
  width: 100%;
  .legal-text{
    max-width: 700px;
    margin-top: 10px;
    div span{
      height: 20px;
    }
    a{
      color: white;
      transition: 0.3s;
      text-decoration: none;
    }
    a:hover{
      color: #ac18e7;
    }
  }
}
.footer-social-media {
  justify-content: space-around !important;
  width: 100%;
  .v-text-field {
    padding: 0 !important;
    margin: 0 !important;
  }
  .v-select{
    max-width: 200px !important;
  }
}
.social-media-buttons {
  .v-icon {
    color: white !important;
  }
  .v-icon:hover{
    color: #ac18e7 !important;
  }
}
.v-divider {
  margin: 5px;
  width: 90%;
  border-style: solid;
  border-image: radial-gradient(rgb(255,255,255), rgb(0,0,0)) 1;
}
.v-divider--vertical{
  height: 10px;
}
</style>
