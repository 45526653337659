<template>
  <v-app>
    <navbar color="secondary" />
    <v-main>
      <router-view />
    </v-main>
    <footerAcs class="footer" />
  </v-app>
</template>

<script>
import navbar from './components/navbar/navbar.vue';
import footerAcs from './components/footer/footer.vue';

export default {
  name: 'App',
  components: {
    navbar, footerAcs,
  },

  data: () => ({
    //
  }),
};
</script>

<style lang="scss" scoped>
  .footer{
    background-color: #111;
  }
</style>
