<template>
  <v-container fluid
    class="pa-0 cont-home"
  >
    <div class="cont-spiral">
      <div class="spiral"></div>
      <div class="text-button spiral-text">{{ $t('studio.design') }}</div>
    </div>
    <div class="cont-spiral2">
      <div class="spiral2"></div>
      <div class="text-button spiral-text2">{{ $t('studio.games') }}</div>
    </div>
  </v-container>
</template>

<script>
export default {
  name: 'Home',
};
</script>

<style lang="scss" scooped>
  .spiral-text{
    position: absolute;
    top: 150px;
    left: 20%;
  }
  .cont-spiral, .cont-spiral2{
    height: 370px;
    cursor: pointer;
  }
  .spiral{
    position:absolute;
    top: 0;
    right: 0;
    width: 0;
    height: 370px;
    background-image: url('../assets/images/espiral-morada.jpg');
    background-size: cover;
    transition: 1.5s ease;
  }
  .cont-spiral:hover .spiral{
    width: 100%;
  }
  .spiral-text2{
    font-size: 12em !important;
    position: absolute;
    bottom: 150px;
    right: 20%;
  }
  .spiral2{
    position:absolute;
    top: 370px;
    left: 0;
    width: 0;
    height: 370px;
    background-image: url('../assets/images/espiral-morada2.jpg');
    background-size: cover;
    transition: 1.5s ease;
  }
  .cont-spiral2:hover .spiral2{
    width: 100%;
  }
  .cont-home{
    height: 740px;
  }
</style>
