<template>
  <v-app-bar app :color="$props.color" dark height="60px">
    <div class="d-flex align-center">
      <v-img
        alt="ACS logo"
        class="ml-2 acs-logo"
        :src="require('../../assets/logos/logo_hor.png')"
      />
    </div>
  </v-app-bar>
</template>

<script>
export default {
  props: ['color', 'textcolor'],
};
</script>

<style>
.acs-logo{
  width: 200px !important;
  height: auto;
}
</style>
